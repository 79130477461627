import { Component, OnInit } from '@angular/core';




@Component({
  selector: 'app-standup',
  templateUrl: './standup.component.html',
  styleUrls: ['./standup.component.scss']
})
export class StandupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
