import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

import { VideoComponent } from './video/video.component';
// import { ShowsComponent } from './shows/shows.component';
import { PodcastsComponent } from './podcasts/podcasts.component';
import { PhotosComponent } from './photos/photos.component';
import { SkitsComponent } from './skits/skits.component';
import { StandupComponent } from './standup/standup.component';
import { ContactComponent } from './contact/contact.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
 
  { path: 'video', component: VideoComponent },
  // { path: 'shows', component: ShowsComponent },
  { path: 'podcasts', component: PodcastsComponent },
  { path: 'photos', component: PhotosComponent },
  { path: 'skits', component: SkitsComponent },
  { path: 'standup', component: StandupComponent },
  { path: 'contact', component: ContactComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
