<div class="outer-container justify-content-center">
    <div class="text-container justify-content-center row">
        <p class="blur-container justify-content-center">
            <br>
            <span class="x-large">
                Robbie Bernstein
            </span>    
            <br>
            <span class="large">
            <!-- P.O. Box XXXX -->
            
           <!-- NY, NY XXXXX -->
             <br>         
            <a href="mailto:robsnewsroom@gmail.com">robsnewsroom@gmail.com</a>      
        </span>
        <p>           
    </div> 
</div>

        



