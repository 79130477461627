<header>

  <nav class="navbar navbar-expand-lg bg-primary navbar-light fixed-top navbar-custom">
    <a class="navbar-brand" href="#">Robbie <span class="fire">The Fire</span> Bernstein</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">

        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="custom-nav-link" routerLinkActive="active" 
          [routerLinkActiveOptions]="{exact: true}" routerLink="/">Home</a>
        </li>
        <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="custom-nav-link" routerLinkActive="active" 
          [routerLinkActiveOptions]="{exact: true}"  routerLink="/bio">Bio</a>
        </li> -->
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="custom-nav-link" routerLinkActive="active" 
          [routerLinkActiveOptions]="{exact: true}" routerLink="/video">Rob's Newsroom</a>
        </li>
        <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="custom-nav-link" routerLinkActive="active" 
          [routerLinkActiveOptions]="{exact: true}" routerLink="/shows">Live Shows</a>
        </li> -->
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="custom-nav-link" routerLinkActive="active" 
          [routerLinkActiveOptions]="{exact: true}"  routerLink="/podcasts">Podcasts</a>
        </li>

        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="custom-nav-link" routerLinkActive="active" 
          [routerLinkActiveOptions]="{exact: true}" routerLink="/standup">Stand Up</a>
        </li>

        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="custom-nav-link" routerLinkActive="active" 
          [routerLinkActiveOptions]="{exact: true}" routerLink="/skits">Sketches</a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="custom-nav-link" routerLinkActive="active" 
          [routerLinkActiveOptions]="{exact: true}" routerLink="/contact">Contact</a>
        </li>
      </ul>
      <div class="hidden-xs collapse navbar-collapse">
        <ul class="navbar-nav ml-auto hidden-xs">
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <img class="crown" src="assets/img/iconmonstr-crown-2-24.png" alt="">King of the Caucs
          </li>
        </ul>
      </div>
      
    </div>
  </nav>

</header>


<div class="container wrapper">
<router-outlet></router-outlet>

<div class="push"></div>
</div>




<footer class="footer">
  <div class="container">
      <div class="row no-gutters">
        <div class="col-6">
          <span class="built">Site Built by <a href="mailto:matt.fresta@gmail.com">Matt Fresta</a></span>
        </div>
  
        <div class="col-6">
          <nav>
            <ul>
              <li><a href="https://twitter.com/RobbieTheFire?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank"><span id="social"><img class="social" src="assets/img/tw_sq.png" alt="..."></span></a></li>
              <li><a href="https://soundcloud.com/user-534644803-655409170" target="_blank"><span id="social"><img class="social" src="assets/img/sc_sq.png" alt="..."></span></a></li>
              <li><a href="https://www.youtube.com/channel/UCgdJW-iyPk4DaPDTpauQ8vQ?view_as=subscriber" target="_blank"><span id="social"><img class="social" src="assets/img/yt_sq.png" alt="..."></span></a></li>
              <!-- <li><a href="https://www.facebook.com/" target="_blank"><span id="social"><img class="social" src="assets/img/fb_sq.png" alt="..."></span></a></li>
              <li><a href="https://www.instagram.com/" target="_blank"><span id="social"><img class="social" src="assets/img/ig_sq.png" alt="..."></span></a></li> -->
              
            </ul>
          </nav>
        </div>
      </div>

  </div>


</footer>