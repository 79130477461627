<div class="outer-container">
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 justify-content-center">
            <div class="pod-container">

                 <a href="https://soundcloud.com/user-534644803-655409170" target="_blank"><img src="assets/img/rym_pod.jpg" alt="..."></a>
             
               
                 <iframe width="100%" height="400px" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/282082679&color=%23e85d2d&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            
                 
                 <button class="btn-subscribe btn-left" onclick="window.location.href = 'https://podcasts.apple.com/us/podcast/run-your-mouth-podcast/id1211469807'; target='_blank'" method="get" target="_blank">Subscribe</button>
                 <button class="btn-subscribe btn-" onclick="window.location.href = 'http://feeds.soundcloud.com/users/soundcloud:users:282082679/sounds.rss';" method="get" target="_blank">RSS</button>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 justify-content-center">
            <div class="pod-container">

                 <a href="https://gasdigitalnetwork.com/gdn-show-channels/part-of-the-problem/" target="_blank"><img src="assets/img/potp_pod.jpg" alt="..."></a>  
           
                <iframe src="//art19.com/shows/d0ab5d6f-1972-4441-b3f8-6b61d09f7f15/embed?playlist_type=playlist&amp;playlist_size=10" style="width: 100%; height: 400px; border: 0 none;" scrolling="no" allowfullscreen="" webkitallowfullscreen="" mozallowfullscreen="" oallowfullscreen="" msallowfullscreen=""></iframe>
                
            <div>                
                <button class="btn-subscribe btn-left" onclick="window.location.href = 'https://podcasts.apple.com/us/podcast/part-of-the-problem/id833706616?mt=2';" method="get" target="_blank">Subscribe</button>
                
                <button class="btn-subscribe btn-right" onclick="window.location.href = ' https://rss.art19.com/part-of-the-problem';" method="get" target="_blank">RSS</button>
            </div>
            </div>
        </div>       
    </div>



    <div class="row">

        <div class="col-md-12 col-sm-12 justify-content-center" >
            
            
        
            <div class="video-container justify-content-center">
                <!-- <iframe title="Embed Player" src="//play.libsyn.com/embed/destination/id/3183359/height/412/theme/modern/size/large/thumbnail/yes/custom-color/c53234/category/comedy/playlist-height/200/direction/forward/download/yes" height="412" width="100%" scrolling="no" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true" style="border: none;"></iframe> -->
                <!-- <video class="bcvid" controls poster="assets/img/blackcapt.jpg" >
                <source src="https://res.cloudinary.com/djaihwpfb/video/upload/v1621952901/robbiethefire/Black_Captain_promo_1_yhqfeo.mp4">
                </video> -->

                <iframe title="Embed Player" src="//play.libsyn.com/embed/destination/id/3183359/height/492/theme/modern/size/large/thumbnail/yes/custom-color/c53234/playlist-height/280/direction/forward/download/yes" height="492" width="100%" scrolling="no" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true" style="border: none;"></iframe>

                <p class="bclink">
                    
                    <a   href="https://theblackcaptain.com/" target="_blank">TheBlackCaptain.com</a>
                </p>

            </div>  
        </div>

    </div>
</div>