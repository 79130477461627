import { Component, OnInit } from '@angular/core';

@Component({ selector: 'app-skits', 

templateUrl: './skits.component.html',
/* template: `
<div class="outer-container">
  <div class="row justify-content-center">

    <div *ngFor="let vid of vids" class="col-md-6 col-sm-12 justify-content-center">
      
        <p class="fire2">{{vid}}</p>
      
        <div class="video-container justify-content-center">
          <!--<video poster="assets/img/rob1.png" controls>-->
          
          <video controls>
            
            <source src="{{'assets/vid/' + vid + '.mp4'}}" type="video/mp4">
             
            Your browser does not support the video tag.
          </video> 
        </div>
    </div>

  </div>
</div>






`,  */

styleUrls: ['./skits.component.scss'] })
export class SkitsComponent implements OnInit {
    vids = ["Ridge Wallet Spec Commercial",
            "Detective Ective",
            "Newless Newsroom",

          "CARANTINED 0 -  Don't Get Stuck In Your Car",
          "CARANTINED 1 - Going To The Bathroom",
        "CARANTINED 2 - Don_t be the last to Snap",
      "CARANTINED 3 - Road Songs",
      "CARANTINED 4 + 5 - Fresh Air and Excersize",
      "CARANTINED 6 - Wearing Makeup",
      "CARANTINED 7 - Ignore The Voices",
      "CARANTINED 8 - Get Out Of NYC",
      "CARANTINED 9 - FINISHED GOOD TO GO"


    ];
    //pageOfVids: Array<any>;



    // vids = ["assets/vid/Robs Newsroom Episode 2.mp4",
    // "assets/vid/Rob's Newsroom Episode 3.mp4",
    // "assets/vid/Robs Newsroom Episode 4.mp4", 
    // "assets/vid/ROBS NEWSROOM EPISODE 5.mp4", 
    // "assets/vid/Robs NewsRoom Episode 6.mp4",
    //  "assets/vid/Robs Newsroom Episode 7.mp4"];
    //  pageOfItems: Array<any>;

    constructor() { }

    ngOnInit() {
        // an example array of 150 items to be paged
        //this.items = Array(150).fill(0).map((x, i) => ({ id: (i + 1), name: `Item ${i + 1}`}));
        // this.vids = ["assets/vid/Robs Newsroom Episode 2.mp4",
        // "assets/vid/Rob's Newsroom Episode 3.mp4",
        // "assets/vid/Robs Newsroom Episode 4.0.mp4",
        // "assets/vid/Robs Newsroom Episode 4.1 Life After Trump.mp4",
        // "assets/vid/Robs Newsroom Episode 4.2 - Change The System..mp4",
        // "assets/vid/Robs Newsroom Episode 4.3 How to avoid getting deported.mp4",
        // "assets/vid/Robs Newsroom Episode 4.4 - Rex Tillerson Secretary of State.mp4", 
        // "assets/vid/ROBS NEWSROOM EPISODE 5.mp4", 
        // "assets/vid/Robs NewsRoom Episode 6.mp4",
        //  "assets/vid/Robs Newsroom Episode 7.mp4"];
    }

    onChangePage(pageOfVids: Array<any>) {
        // update current page of items
        //this.pageOfVids = pageOfVids;
    }
}