<div class="outer-container">
    <div class="row justify-content-center">
  
        <div  class="col-md-12 col-sm-12 justify-content-center">
        
             <p class="fire2">Not Getting The Vax</p>
       
            <div class="video-container justify-content-center">

                <video controls poster="">
                <source src="https://res.cloudinary.com/dxcpgdpic/video/upload/v1635162752/fireVids/Not_Getting_The_Vax_mxpekp.mp4">
                </video>

            </div>
            
        </div>

        <div  class="col-md-12 col-sm-12 justify-content-center">
            
            <p class="fire2">Fauci is a Liar</p>
        
            <div class="video-container justify-content-center">

                <video controls poster="">
                <source src="https://res.cloudinary.com/dxcpgdpic/video/upload/v1635162678/fireVids/Fauci_Is_A_Liar_qaamc0.mp4">
                </video>

            </div>
        </div>


        
        <div  class="col-md-12 col-sm-12 justify-content-center">
            
            <p class="fire2">Vax Back to Normal</p>
        
            <div class="video-container justify-content-center">
               
                <video controls poster="">
                <source src="https://res.cloudinary.com/dxcpgdpic/video/upload/v1635162685/fireVids/Vax_Back_to_Normal_gqcwxo.mp4">
                </video>

            </div>
        </div>

        <div  class="col-md-12 col-sm-12 justify-content-center">
            
            <p class="fire2">Press Guy of the People</p>
        
            <div class="video-container justify-content-center">
               
                <video controls poster="assets/img/press_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271235/fire/Press_Guy_qqw2ct.mp4">
                </video>

            </div>
        </div>

        <div  class="col-md-12 col-sm-12 justify-content-center">
            
            <p class="fire2">Being in Portland</p>
        
            <div class="video-container justify-content-center">
               
                <video controls poster="assets/img/portland_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271249/fire/Portland_lsecwv.mp4">
                </video>

            </div>
        </div>





  
    </div>
</div>
  