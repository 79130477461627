<div class="row justify-content-center outer-container-2">
    <div class="slide-container">
        <div id="carouselExampleControls" class="carousel slide justify-content-center" data-ride="carousel">
            <div class="carousel-inner justify-content-center">
                <div class="carousel-item active justify-content-center">               
                    <img class="d-block w-100" src="assets/img/polaroid_porcfest.png" alt="First slide">             
                </div>
                <div class="carousel-item justify-content-center">
                    <img class="d-block w-100" src="assets/img/polaroid_steamboat.png" alt="Second slide">
                </div>
                 <div class="carousel-item justify-content-center">
                    <img class="d-block w-100" src="assets/img/polaroid_bk.png" alt="Third slide">
                </div>
                 <div class="carousel-item justify-content-center">
                    <img class="d-block w-100" src="assets/img/polaroid_cfc.png" alt="Fourth slide">
                  
                </div>
                <div class="carousel-item justify-content-center">
                    <img class="d-block w-100" src="assets/img/polaroid_recap.png" alt="Fifth slide">

                </div>
                <div class="carousel-item justify-content-center">
                    <img class="d-block w-100" src="assets/img/portland_polaroid2021.png" alt="Sixth slide">

                </div>
                 
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>    
    </div>
</div>