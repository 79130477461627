<div class="outer-container">
    <div class="row justify-content-center">
  
        <div  class="col-md-12 col-sm-12 justify-content-center">
        
             <p class="fire2">Ridge Wallet Spec Commercial</p>
       
            <div class="video-container justify-content-center">

                <video controls poster="assets/img/ridge_thumbnail.png">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271271/fire/ridge_wallet_ad_q7z5ko.mp4">
                </video>

            </div>
            
        </div>
        <div  class="col-md-12 col-sm-12 justify-content-center">
        
             <p class="fire2">Bernie Sanders' New Book</p>
       
            <div class="video-container justify-content-center">

                <video controls poster="assets/img/mainvid_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271260/fire/berniebook_xljhkj.mp4">
                </video>

            </div>
            
        </div>

        <div  class="col-md-12 col-sm-12 justify-content-center">
            
            <p class="fire2">Detective Ective</p>
        
            <div class="video-container justify-content-center">

                <video controls poster="assets/img/det_ective_thumb.png">
                <source src="https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092159/rob/Detective_Ective_aqttwv.mp4">
                </video>

            </div>
        </div>

        <div  class="col-md-12 col-sm-12 justify-content-center">
            
            <p class="fire2">Hunter Biden Art Auction</p>
        
            <div class="video-container justify-content-center">
               
                <video controls poster="">
                <source src="https://res.cloudinary.com/dxcpgdpic/video/upload/v1635162766/fireVids/Hunter_Biden_Art_Auction_p7cl2e.mp4">
                </video>

            </div>
        </div>

        <div  class="col-md-12 col-sm-12 justify-content-center">
            
            <p class="fire2">Totally Nonviolent Sketch with Jokes Not About Covid</p>
        
            <div class="video-container justify-content-center">
               
                <video controls poster="">
                <source src="https://res.cloudinary.com/dxcpgdpic/video/upload/v1635162738/fireVids/Totally_Nonviolent_Joke_Sketch_With_Jokes_Not_About_Covid_ude6td.mp4">
                </video>

            </div>
        </div>

        <div  class="col-md-12 col-sm-12 justify-content-center">
            
            <p class="fire2">Newsless Newsroom</p>
        
            <div class="video-container justify-content-center">
               
                <video controls poster="assets/img/newsless_thumb.png">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624270520/fire/Newsless_Newsroom_p8k5q1.mp4">
                </video>

            </div>
        </div>







        <div class="carantine fire">
            RUN YOUR MOUTH 2020 RECAP

        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">Pt.1 - We All Panicked</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/recap1_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271303/fire/Pt.1_-_We_All_Panicked_-RYM_End_of_The_Year_Recap-_q8ixn2.mp4">
                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">Pt.2 - Chris Cuomo</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/recap2_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271203/fire/Pt.2_-_Chris_Cuomo_-RYM_End_of_The_Year_Recap-_efct4p.mp4">
                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">Pt.3 - Listen to the Scientists</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/recap3_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271217/fire/Pt.3_-_Listen_to_The_Scientists_-RYM_2020_End_of_The_Year_Recap-_ey6joq.mp4">
                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">Pt.4 - Tech Censorship</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/recap4_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271294/fire/Pt.4_-_Tech_Sensorship_-RYM_2020_End_of_The_Year_Recap-_agslc1.mp4">
                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">Pt.5 - Protests</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/recap5_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271288/fire/Pt.5_-_Protests_-RYM_2020_End_of_The_Year_Recap-_m2prci.mp4">
                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">Pt.6 - Repo</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/recap6_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271278/fire/Pt.6_-_Repo_-RYM_2020_End_of_The_Year_Recap-_dgg4gq.mp4">
                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">Pt.7 - Conspiracy & The Year's Biggest Tragedy</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/recap7_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271292/fire/Pt.7_-_Conspiracy_-_The_Year-s_Biggest_Tragedy_-RYM_2020_End_of_The_Year_Recap_Finale-_pfnqom.mp4">
                </video>
            </div>
        </div>

        <div class="carantine fire">
            CARANTINED
            <br>
    <h4>Thanks to Caronavirus I got stuck in my car. Here's some tips from being Carantined. </h4>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">CARANTINED 0 -  Don't Get Stuck In Your Car</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/car0_thumb.png">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271253/fire/CARANTINED_0_-_Don_t_Get_Stuck_In_Your_Car_vlaoie.mp4">
                </video>
            </div>
        </div>



        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">CARANTINED 1 - Going To The Bathroom</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/car1_thumb.png">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271260/fire/CARANTINED_1_-_Going_To_The_Bathroom_zejxcl.mp4">
                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">CARANTINED 2 - Don't be the last to Snap</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/car2_thumb.png">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624270583/fire/CARANTINED_2_-_Don_t_be_the_last_to_Snap_n70vaa.mp4">
                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">CARANTINED 3 - Road Songs</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/car3_thumb.png">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271235/fire/CARANTINED_3_-_Road_Songs_qylhvp.mp4">
                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">CARANTINED 4 + 5 - Fresh Air and Excersize</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/car4_thumb.png">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624270570/fire/CARANTINED_4_5_-_Fresh_Air_and_Excersize_h55mur.mp4">
                </video>
            </div>
        </div>
        
  
        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">CARANTINED 6 - Wearing Makeup</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/car6_thumb.png">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624270563/fire/CARANTINED_6_-_Wearing_Makeup_byrpqq.mp4">

                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
        
            <p class="fire2">CARANTINED 7 - Ignore The Voices</p>
        
            <div class="video-container justify-content-center">
                <video poster="assets/img/vid_coming_thumb.png">
                    <source src="">

                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">CARANTINED 8 - Get Out Of NYC</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/car8_thumb.png">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271231/fire/CARANTINED_8_-_Get_Out_Of_NYC_heaqqb.mp4">
                
          
                </video>
            
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">CARANTINED 9 - FINISHED GOOD TO GO</p>
        
            <div class="video-container justify-content-center">

                <video controls poster="assets/img/car9_thumb.png">
                
                    <source src="https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631093694/rob/CARANTINED_9_-_FINISHED_GOOD_TO_GO__exa4eq_rswnpi.mp4">
                    
                  
                </video> 
         
            </div>
        </div>

        <div  class="col-md-12 col-sm-12 justify-content-center">
            
            <p class="fire2">Mortal Kombat Pitch Meeting</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/mkpitch_thumb.jpg">
                <source src="https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092534/rob/Mk_pitch_njtyw3.mp4">
                </video>
            </div>
        </div>


        <div class="carantine fire">
            CONFUSED FELLAS

        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">Episode 1</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/cf1_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624270570/fire/Confused_Fellas_episode_1_dwi5ky.mp4">
                </video>
            </div>
        </div>

        <div  class="col-md-6 col-sm-12 justify-content-center">
            
            <p class="fire2">Episode 2</p>
        
            <div class="video-container justify-content-center">
                <video controls poster="assets/img/cf2_thumb.jpg">
                <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271254/fire/Confused_fellas_episode_2_lyh4pt.mp4">
                </video>
            </div>
        </div>
  
    </div>
</div>
  