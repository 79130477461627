import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AlertModule } from 'ngx-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VideoComponent } from './video/video.component';

import { PodcastsComponent } from './podcasts/podcasts.component';
import { SkitsComponent } from './skits/skits.component';
import { PhotosComponent } from './photos/photos.component';
import { HomeComponent } from './home/home.component';
// import { ShowsComponent } from './shows/shows.component';
import { ContactComponent } from './contact/contact.component';
import { StandupComponent } from './standup/standup.component';

@NgModule({
  declarations: [
    AppComponent,
    VideoComponent,
   
    PodcastsComponent,
    SkitsComponent,
    PhotosComponent,
    HomeComponent,
    // ShowsComponent,
    ContactComponent,
    StandupComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AlertModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
