

<div class="outer-container">
  <div class="row">

    <div class="info-container col-md-12 col-sm-12 justify-content-center">
    <p class="fire2"> 
      
      <!-- Greetings. Welcome to my website.  Please enjoy all the complimentary content. Below is this month's featured video. On other pages you can find the podcasts I've done, sketches and well you probably know how websites work. -->

    </p>
    </div>
  </div>

  <div class="shows justify-content-center">

    <img src="assets/img/robkong.png" alt="">

    <!-- <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSsrBs8kV6f7-0PXI2XxCWjRNkL03m6mAJ-hyEYGtlWloEv0kTokOLsVkitVKtwjnsss2ae2mnW0jpE/pubhtml?gid=2084945952&amp;single=true&amp;widget=true&amp;headers=false"></iframe> -->
         <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTI7p3HftO8kFz18WpUgZ3g8hhRlPGlF54IwSINwKJh8kUR_5BBQw2JRNdVhrHXH_DS1g3ZqUDtfyZy/pubhtml?gid=0&single=true"></iframe>
  </div>



  <!-- <div class="row justify-content-center">
    <div class="video-container col-md-12 col-sm-12 justify-content-center">
      <video height="400" width="956" controls preload="metadata" poster="assets/img/mainvid_thumb.jpg">
                
          <source src="https://res.cloudinary.com/du1lds3v5/video/upload/v1624271260/fire/berniebook_xljhkj.mp4">
        
          Your browser does not support the video tag.
      </video> 
    </div>
  </div> -->
</div>