import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video',
  // templateUrl: './video.component.html',
  template: `
  <div class="outer-container">
    <div class="row justify-content-center">
      <div *ngFor="let url of urls; let i = index" class="col-md-4 col-sm-12 justify-content-center">
      <p class="fire2">{{vids[i]}}</p>

          <div class="video-container justify-content-center">

          
          <video controls poster="{{thumbnails[i]}}">
            <source src="{{url}}">

            </video>
          

      </div>

    
    </div>


  </div>
      
    
  <!--
      <div class="justify-content-center card-footer pb-0 pt-3">
        <jw-pagination [pageSize]="4" [maxPages]="20" [items]="vids" (changePage)="onChangePage($event)"></jw-pagination>
      </div>
  -->

  `,
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  
  vids = [
    "Behind The Scenes Footage",
    "Why Insurance Companies Shouldn't Cover Pre-Existing Conditions",
    /* SEASON 1 */
    "1.2",
    "1.3",
    "1.4",
    "1.5", 
    "1.6",
    "1.7",
    "1.75",
    "1.8",
    "1.85",
    "1.9",
    "1.10",
    "1.11",
    "1.12",
    "1.13",

    /* SEASON 2 */
    "2.0 - Oregon Militia",
    "2.1 - Global Warming",
    "2.2 - Flint Michigan Water Crises",
    "2.3 - Trump's Little Hands",
    "2.4 - Hillary Clinton Email Scandal",
    "2.5 - North Carolina Transgender Law",
    "2.6 - Slavery",
    "2.7 - The VA",
    "2.8 - Black Lives Matter",
    "2.9 - The Olympics",

    /* SEASON 3 */
    "3.1 - We Don't Have A Country",
    "3.2 -  Election Special",
    "3.3 - Trump and the Media",
    "3.4 - Cancel the Election",
    "3.5 - Replace the Candidates",
    "3.6 - AT&T Time Warner Merger",

       /* SEASON 4 */
    "4.1 - Life After Trump",
    "4.2 - Change The System.",
    "4.3 - Avoid Getting Deported",
    "4.4 - Rex Tillerson", 

       /* SEASON 5 */
    "5.0 - Newsroom Returns",
    "5.1 - Trump Immigration Ban",
    "5.2 - Republican Healthcare Reform",
    "5.3 - Failed Healthcare Reform",
    "5.4 - Rusty Fixes Healthcare",
    "5.6 - Behind The Scenes - Hiring a New Menuhin",

       /* SEASON 6 */
    "6.1 - Let Trump Work",
    "6.2 - State Debt and Pension Liabilities",
    "6.3 - Finale For Now",
    "6.4 - Legit Finale - Federal Reserve"]
    .reverse();


    
    urls = [
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624271275/fire/Behind_The_Scenes_Footage_vt4mfv.mp4",
    "https://res.cloudinary.com/dxcpgdpic/video/upload/v1623847076/fireVids/Why_Insurance_Companies_Shouldn_t_Cover_Pre-Existing_Conditions_ovgvga.mp4",
    /* SEASON 1 */
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624271271/fire/1.2_j3wduw.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092297/rob/1.3_rbealn.mp4",
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624270566/fire/1.4_kni4ob.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631093922/rob/1.5_skov3b.mp4", 
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631093971/rob/1.6_jtfcbu.mp4",
    "https://res.cloudinary.com/dxcpgdpic/video/upload/v1623847475/fireVids/1.7_u99h2i.mp4",
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624270533/fire/1.75_uoykzr.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092523/rob/1.8_llantp.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631093870/rob/1.85_ezoq0y.mp4",
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624271283/fire/1.9_url1pw.mp4",
    "https://res.cloudinary.com/dxcpgdpic/video/upload/v1623847124/fireVids/1.10_mxwtpj.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092253/rob/1.11_gzg09w.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092519/rob/1.12_ziafzn.mp4",
    "https://res.cloudinary.com/dxcpgdpic/video/upload/v1623847588/fireVids/1.13_xm84vv.mp4",

    /* SEASON 2 */
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092379/rob/2.0_-_Oregon_Militia_avh7k1.mp4",
    "https://res.cloudinary.com/dxcpgdpic/video/upload/v1623847416/fireVids/2.1_-_Global_Warming_p8drpv.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092208/rob/2.2_-_Flint_Michigan_Water_Crises_wavrx5.mp4",
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624270528/fire/2.3_-_Trump_s_Little_Hands_sp2nh6.mp4",
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624270583/fire/2.4_-_Hillary_Clinton_Email_Scandal_sqyl5d.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092534/rob/2.5_-_North_Carolina_Transgender_Law_kg1o5s.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631091766/rob/2.6_-_Slavery_ifhk1x.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092112/rob/2.7_-_The_VA_v0st7r.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092062/rob/2.8_-_Black_Lives_Matter_c7zs5y.mp4",
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624270557/fire/2.9_-_The_Olympics_clglfp.mp4",

    /* SEASON 3 */
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624271247/fire/3.1_-_We_Don_t_Have_A_Country_cohpwy.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631093875/rob/3.2_-_Election_Special_eoitfa.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631093931/rob/3.3_-_Trump_and_the_Media_spdzca.mp4",
    "https://res.cloudinary.com/dxcpgdpic/video/upload/v1623847529/fireVids/3.4_-_Cancel_the_Election_egfxvr.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092522/rob/3.5_-_Replace_the_Candidates_cd8p4q.mp4",
    "https://res.cloudinary.com/dxcpgdpic/video/upload/v1623847442/fireVids/3.6_-_AT_T_Time_Warner_Merger_j3xy3v.mp4",

       /* SEASON 4 */
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092340/rob/4.1_-_Life_After_Trump_atcxaj.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631091751/rob/4.2_-_Change_The_System._so7umn.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092516/rob/4.3_-_Avoid_Getting_Deported_nkpyvv.mp4",
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624270590/fire/4.4_-_Rex_Tillerson_ivzs7r.mp4", 

       /* SEASON 5 */
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092463/rob/5.0_-_Newsroom_Returns_p2kcel.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092464/rob/5.1_-_Trump_Immigration_Ban_hcicim.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631092537/rob/5.2_-_Republican_Healthcare_Reform_v0anru.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631093960/rob/5.3_-_Failed_Healthcare_Reform_dchcpl.mp4",
    "https://res.cloudinary.com/dxcpgdpic/video/upload/v1623847220/fireVids/5.4_-_Rusty_Fixes_Healthcare_g9tz0u.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631091784/rob/5.6_-_Behind_The_Scenes_-_Hiring_a_New_Menuhin_s6micm.mp4",

       /* SEASON 6 */
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624270588/fire/6.1_-_Let_Trump_Work_dpaejn.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631091771/rob/6.2_-_State_Debt_and_Pension_Liabilities_hcrsqj.mp4",
    "https://res.cloudinary.com/dlwnz9mz5/video/upload/v1631091776/rob/6.3_-_Finale_For_Now_qj8n13.mp4",
    "https://res.cloudinary.com/du1lds3v5/video/upload/v1624270561/fire/6.4_-_Legit_Finale_-_Federal_Reserve_seibvl.mp4"]
    .reverse();

    thumbnails = [
      "assets/img/behind_thumb.png",
      "assets/img/pre_thumb.png",
      /* SEASON 1 */
      "assets/img/1.2_thumb.png",
      "assets/img/1.3_thumb.png",
      "assets/img/1.4_thumb.png",
      "assets/img/1.5_thumb.png", 
      "assets/img/1.6_thumb.png",
      "assets/img/1.7_thumb.png",
      "assets/img/1.75_thumb.png",
      "assets/img/1.8_thumb.png",
      "assets/img/1.85_thumb.png",
      "assets/img/1.9_thumb.png",
      "assets/img/1.10_thumb.png",
      "assets/img/1.11_thumb.png",
      "assets/img/1.12_thumb.png",
      "assets/img/1.13_thumb.png",
  
      /* SEASON 2 */
      "assets/img/2.0_thumb.png",
      "assets/img/2.1_thumb.png",
      "assets/img/2.2_thumb.png",
      "assets/img/2.3_thumb.png",
      "assets/img/2.4_thumb.png",
      "assets/img/2.5_thumb.png",
      "assets/img/2.6_thumb.png",
      "assets/img/2.7_thumb.png",
      "assets/img/2.8_thumb.png",
      "assets/img/2.9_thumb.png",
  
      /* SEASON 3 */
      "assets/img/3.1_thumb.png",
      "assets/img/3.2_thumb.png",
      "assets/img/3.3_thumb.png",
      "assets/img/3.4_thumb.png",
      "assets/img/3.5_thumb.png",
      "assets/img/3.6_thumb.png",
  
         /* SEASON 4 */
      "assets/img/4.1_thumb.png",
      "assets/img/4.2_thumb.png",
      "assets/img/4.3_thumb.png",
      "assets/img/4.4_thumb.png", 
  
         /* SEASON 5 */
      "assets/img/5.0_thumb.png",
      "assets/img/5.1_thumb.png",
      "assets/img/5.2_thumb.png",
      "assets/img/5.3_thumb.png",
      "assets/img/5.4_thumb.png",
      "assets/img/5.6_thumb.png",
  
         /* SEASON 6 */
      "assets/img/6.1_thumb.png",
      "assets/img/6.2_thumb.png",
      "assets/img/6.3_thumb.png",
      "assets/img/6.4_thumb.png"]
      .reverse();


  constructor() { }

  ngOnInit() {

  }


}
